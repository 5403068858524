import { TypeUserRole } from './user.model';

type TypeAddress = {
  city?: string;
  flat?: string;
  index?: string;
  house?: string;
  block?: string;
  region?: string;
  street?: string;
  cityType?: string;
  flatType?: string;
  houseType?: string;
  blockType?: string;
  kladrCode?: string;
  streetType?: string;
  addressfull: string;
  cityDistrict?: string;
  countryISOCode?: string;
  cityDistrictType?: string;
};

export type OrgType = {
  orgTypeId: number;
  OrgType: string;
};

export type TypeOrganization = {
  INN?: string | null;
  KPP?: string | null;
  name: string;
  OGRN?: string | null;
  OKPO?: string | null;
  email?: string;
  OKVED?: string | null;
  canEdit?: boolean;
  addressJur: TypeAddress;
  addressFact: TypeAddress;
  employeeRole?: TypeUserRole;
  organizationId?: string;
  canViewInvites?: boolean;
  logo?: {
    downloadUrl: string;
    fileContentType: string;
    fileName: string;
    fileSize: number;
    id: string;
  };
  type?: OrgType;
  contact?: string;
  isScout?: boolean;
  isExperiment?: boolean;
  manager?: {
    fullName?: string;
    id?: string;
  };
  representativePosition?: string;
  serviceProvider?: boolean;
  phone?: string;
  newOwner?: string;
};

type TChangeOrganizationPayload = {
  name: string;
  email: string;
  phone: string;
  contact: string;
  logo: string;
  addressFact: TypeAddress;
  addressJur: TypeAddress;
  orgTypeId: number;
  specializationId: number;
  INN: string;
  OGRN: string;
  KPP: string;
  OKPO: string;
  OKVED: string;
};

export enum EOrgType {
  OOO = 1,
  OAO = 2,
  ZAO = 3,
  IP = 4,
  PAO = 5,
  TOO = 6,
  KFH = 7,
  SPK = 8,
  AO = 9,
  KFH_G = 10,
}

export type { TChangeOrganizationPayload };
