import { Button } from '@farmlink/farmik-ui';
import { useRef, useState, FC, ChangeEvent } from 'react';

import { toBase64 } from '../../../../utils/getBase64';
import BinSvg from '../assets/images/bin.svg';

import {
  ActionsButtonsWrapper,
  AvatarImg,
  AvatarImgWrapper,
  CancelButtonWrapper,
  Content,
  DeleteButton,
  DialogTitle,
  Disclaimer,
  ErrorMessage,
  FileNameWrapper,
  FileUploader,
  MsgWrapper,
  SaveButtonWrapper,
  StyledFileNameLabel,
  StyledModalComponent,
  UploadAvatarButtonWrapper,
} from './style';
import LoadImgPlaceholder from './loadImgPlaceholder.svg';

const CommitedFileTypes = ['image/jpeg', 'image/png'];

interface IImageUploadDialogProps {
  imgUrl: string;
  fileName: string;
  onClose: () => void;
  onUpload: (file: File, url: string) => void;
  onClear: () => void;
}

export const ImageUploadDialog: FC<IImageUploadDialogProps> = ({
  imgUrl,
  fileName,
  onClose,
  onUpload,
  onClear,
}) => {
  const fileUploaderRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [previewUrl, setPreviewUrl] = useState(imgUrl);

  const isSaveAllowed = !errorMsg && previewUrl !== imgUrl;

  const handleUploadAvatarImage = event => {
    event.stopPropagation();
    fileUploaderRef.current?.click();
  };

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    const [fileForUpload] = event.target.files;

    if (fileForUpload) {
      if (fileForUpload.size >= 1e7) {
        setErrorMsg('Файл слишком большой, замените');
      } else if (!CommitedFileTypes.includes(fileForUpload.type)) {
        setErrorMsg('Можно загружать файлы формата JPEG и PNG');
      } else {
        setFile(fileForUpload);
        setErrorMsg('');
        const link = await toBase64(fileForUpload);
        setPreviewUrl(link);
      }
    }
  };

  const handleSave = () => {
    if (file && previewUrl) {
      onUpload(file, previewUrl);
    } else {
      onClear();
    }
  };

  const onDeleteSelectedImage = () => {
    setFile(null);
    setPreviewUrl('');
    fileUploaderRef.current.value = '';
  };

  return (
    <StyledModalComponent data-test-id="image-upload-dialog">
      <DialogTitle data-test-id="image-upload-dialog-title">Ваше фото</DialogTitle>
      <Content data-test-id="image-upload-dialog-content">
        <Disclaimer data-test-id="image-upload-dialog-disclaimer">
          Рекомендуется загружать файлы форматом PNG или JPEG, размером не менее 225х225 px, и
          объемом не более 10 мб
        </Disclaimer>
        <AvatarImgWrapper data-test-id="image-upload-dialog-avatar-img-wrapper">
          <AvatarImg
            src={previewUrl || LoadImgPlaceholder}
            data-test-id="image-upload-dialog-avatar-img"
          />
        </AvatarImgWrapper>
        <UploadAvatarButtonWrapper data-test-id="image-upload-dialog-upload-avatar-button-wrapper">
          <Button
            onClick={handleUploadAvatarImage}
            type={'button'}
            color={'primary'}
            dataTestId="image-upload-dialog-upload-avatar-button"
          >
            Загрузить изображение
          </Button>
        </UploadAvatarButtonWrapper>
        <MsgWrapper data-test-id="image-upload-dialog-msg-wrapper">
          {errorMsg ? (
            <ErrorMessage data-test-id="image-upload-dialog-error-message">{errorMsg}</ErrorMessage>
          ) : Boolean(previewUrl) ? (
            <FileNameWrapper data-test-id="image-upload-dialog-file-name-wrapper">
              <StyledFileNameLabel data-test-id="image-upload-dialog-file-name-label">
                {file?.name || fileName}
              </StyledFileNameLabel>
              <DeleteButton
                src={BinSvg}
                onClick={onDeleteSelectedImage}
                data-test-id="image-upload-dialog-delete-button"
              />
            </FileNameWrapper>
          ) : null}
        </MsgWrapper>
      </Content>

      <ActionsButtonsWrapper data-test-id="image-upload-dialog-actions-buttons-wrapper">
        <CancelButtonWrapper data-test-id="image-upload-dialog-cancel-button-wrapper">
          <Button
            onClick={onClose}
            type={'button'}
            color={'default'}
            dataTestId="image-upload-dialog-cancel-button"
          >
            Отменить
          </Button>
        </CancelButtonWrapper>
        <SaveButtonWrapper data-test-id="image-upload-dialog-save-button-wrapper">
          <Button
            onClick={handleSave}
            type={'button'}
            color={'primary'}
            disabled={!isSaveAllowed}
            dataTestId="image-upload-dialog-save-button"
          >
            Сохранить
          </Button>
        </SaveButtonWrapper>
      </ActionsButtonsWrapper>
      <FileUploader
        type="file"
        name="file"
        ref={fileUploaderRef}
        accept={CommitedFileTypes.join(',')}
        onChange={onChangeFile}
        onClick={event => event.stopPropagation()}
        data-test-id="image-upload-dialog-file-uploader"
      />
    </StyledModalComponent>
  );
};
