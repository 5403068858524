import { SimpleSchema2Bridge } from 'uniforms-bridge-simple-schema-2';

import { OrgSchema } from '../NewOrgModal/schema';
import { InputField, SelectField } from '../uniforms';
import { EOrgType } from '../../../../api/models/organization.model';

const regExMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const OrgBigSchema = OrgSchema.extend({
  orgTypeId: {
    type: Number,
    required: true,
    label: 'Тип организации',
    uniforms: {
      component: SelectField,
      dataTestId: 'org-type-id',
      options: [],
      allowEmpty: false,
    },
  },

  name: {
    type: String,
    label: 'Название организации',
    required: true,
    regEx: /^[a-zёа-я0-9.,\-\s]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Не указано',
      regexonchange: /(^$)|(^[а-яёa-z0-9.,\- ]+$)/i,
      dataTestId: 'org-name',
    },
  },

  INN: {
    type: String,
    optional: true,
    min: 10,
    max: 12,
    label: 'ИНН',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'inn',
    },
    custom() {
      const orgTypeId = this.field('orgTypeId').value;
      if (orgTypeId && this.value) {
        let length = 10;

        if (orgTypeId === EOrgType.IP || orgTypeId === EOrgType.KFH_G) {
          length = 12;
        }

        if (orgTypeId === EOrgType.KFH && this?.value?.length > 0 && this?.value?.length !== 10) {
          return 'lengthINN_KFH-short';
        }

        if (this.value.toString().length !== length) {
          if (orgTypeId === EOrgType.IP) return 'lengthINN_IP';
          if (orgTypeId === EOrgType.KFH_G) return 'lengthINN_KFH_G';

          return 'lengthINN';
        }
      }
    },
  },

  addressJur: {
    type: String,
    optional: true,
    label: 'Юридический адрес',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      dataTestId: 'address-jur',
    },
  },

  addressFact: {
    type: String,
    optional: true,
    label: 'Фактический адрес',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      dataTestId: 'address-fact',
    },
  },

  OGRN: {
    type: String,
    optional: true,
    min: 13,
    max: 15,
    label: 'ОГРН',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'ogrn',
    },
    custom() {
      const orgTypeId = this.field('orgTypeId').value;
      if (orgTypeId && this.value) {
        let length = 13;
        if (orgTypeId === EOrgType.IP || orgTypeId === EOrgType.KFH_G) {
          length = 15;
        }
        if (this.value.toString().length !== length) {
          if (orgTypeId === EOrgType.IP) return 'lengthOGRN_IP';
          if (orgTypeId === EOrgType.KFH_G) return 'lengthOGRN_KFH_G';

          return 'lengthOGRN';
        }
      }
    },
  },

  OKVED: {
    type: String,
    optional: true,
    min: 2,
    max: 8,
    label: 'ОКВЭД',
    regEx: /^[0-9.]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^[0-9.]+$)/i,
      dataTestId: 'okved',
      // type: 'number',
      // mask: '99.99.99',
    },
  },

  OKPO: {
    type: String,
    optional: true,
    min: 8,
    max: 10,
    label: 'ОКПО',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'okpo',
    },
    custom() {
      const orgTypeId = this.field('orgTypeId').value;
      if (orgTypeId && this.value) {
        let length = 8;
        if (orgTypeId === EOrgType.IP || orgTypeId === EOrgType.KFH_G) {
          length = 10;
        }
        if (this.value.toString().length !== length) {
          if (orgTypeId === EOrgType.IP) return 'lengthOKPO_IP';
          if (orgTypeId === EOrgType.KFH_G) return 'lengthOKPO_KFH_G';

          return 'lengthOKPO';
        }
      }
    },
  },

  KPP: {
    type: String,
    optional: true,
    min: 9,
    max: 9,
    label: 'КПП',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^\d+$)/i,
      dataTestId: 'kpp',
    },
  },

  email: {
    type: String,
    optional: true,
    regEx: regExMail,
    label: 'E-mail',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      dataTestId: 'email',
    },
  },

  phone: {
    type: String,
    optional: true,
    max: 50,
    label: ' Телефон',
    regEx: /^[a-zёа-я0-9.()+\-\s]+$/i,
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^[а-яёa-z0-9.()+\- ]+$)/i,
      dataTestId: 'org-phone',
    },
  },

  contact: {
    type: String,
    optional: true,
    max: 200,
    label: ' Представитель',
    uniforms: {
      component: InputField,
      placeholder: 'Не указан',
      regexonchange: /(^$)|(^[a-zа-яё\- ]+$)/i,
      dataTestId: 'contact',
    },
  },
});

export const bridge = new SimpleSchema2Bridge(OrgBigSchema);
