import { FC, useState } from 'react';

import HoveredImagePlaceholder from './hoveredImagePlaceholder.svg';
import CameraSvg from './camera.svg';
import UserAvatarSvg from './user.svg';
import {
  Wrapper,
  Avatar,
  AvatarMock,
  AvatarWrapper,
  AvatarMockImgHovered,
  AvatarMockImgWrapper,
  StyledUserAvatarSvg,
  StyledCameraSvg,
  CameraSvgWrapper,
} from './style';
import { ImageUploadDialog } from './ImageUploadDialog';

type AvatarUploaderProps = {
  icon: string;
  fileName: string;
  onUpload: (v: File) => void;
  onCommitImg: () => void;
  onLogoReset: () => void;
};

const AvatarMockImg = () => (
  <AvatarMockImgWrapper>
    <StyledUserAvatarSvg src={UserAvatarSvg} />
    <CameraSvgWrapper>
      <StyledCameraSvg src={CameraSvg} />
    </CameraSvgWrapper>
  </AvatarMockImgWrapper>
);

export const AvatarUploader: FC<AvatarUploaderProps> = ({
  icon,
  fileName,
  onUpload,
  onCommitImg,
  onLogoReset,
}) => {
  const [avatarPreview, setAvatarPreview] = useState<string>();
  const [avatarName, setAvatarName] = useState<string>();
  const [showImageUploadDialog, setShowImageUploadDialog] = useState(false);

  const url = avatarPreview ?? icon;
  const name = avatarName ?? fileName;

  const handleUpload = async (file: File, previewUrl: string) => {
    await onUpload(file);
    setAvatarPreview(previewUrl);
    setAvatarName(file.name);
    onCommitImg();
    setShowImageUploadDialog(false);
  };

  const handleClear = () => {
    onLogoReset();
    setAvatarPreview(undefined);
    setShowImageUploadDialog(false);
  };

  return (
    <Wrapper className={'avatar-wrapper'} data-test-id="avatar-uploader-wrapper">
      <AvatarWrapper
        className={'avatar-preview'}
        onClick={event => {
          event.stopPropagation();
          setShowImageUploadDialog(true);
        }}
        data-test-id="avatar-uploader-wrapper-preview"
      >
        {url ? (
          <>
            <Avatar src={url} data-test-id="avatar-uploader-image" />
            <AvatarMockImgHovered
              src={HoveredImagePlaceholder}
              alt=""
              data-test-id="avatar-uploader-mock-hovered"
            />
          </>
        ) : (
          <AvatarMock data-test-id="avatar-uploader-mock">
            <AvatarMockImg />
            <AvatarMockImgHovered
              src={HoveredImagePlaceholder}
              alt=""
              data-test-id="avatar-uploader-mock-hovered"
            />
          </AvatarMock>
        )}
      </AvatarWrapper>
      {showImageUploadDialog ? (
        <ImageUploadDialog
          imgUrl={url}
          fileName={name}
          onClose={() => {
            setShowImageUploadDialog(false);
          }}
          onUpload={handleUpload}
          onClear={handleClear}
        />
      ) : null}
    </Wrapper>
  );
};
