import SimpleSchema from 'simpl-schema';
import { has } from 'lodash';

const getRegExpMessage = (name: string, label: string): string => {
  const regExpMessageMapping = {
    orgName: `${label} может содержать только цифры, дефисы, пробелы, точки, запятые, символы русского и английского алфавитов`,
    orgPhone: `${label} может содержать только цифры, дефисы, круглые скобки, пробелы, точки, плюс, символы русского и английского алфавитов`,
    OKVED: `${label} может содержать только цифры и точки`,
    OGRN: `${label} может содержать только цифры`,
    OKPO: `${label} может содержать только цифры`,
    KPP: `${label} может содержать только цифры`,
    INN: `${label} может содержать только цифры`,
    email: `${label} введен неверно`,
    newOwner: `${label} введен неверно`,
    phone: `${label} введен неверно`,
    roleName: `${label} может содержать только цифры, буквы русского и английского алфавитов, круглые скобки, пробел, дефис`,
  };

  return has(regExpMessageMapping, name)
    ? regExpMessageMapping[name]
    : `${label} может содержать только цифры, символы русского и английского алфавитов`;
};

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      required: '{{{label || name}}} обязательно для заполнения',
      minString: '{{{label}}} не может быть меньше {{min}} знаков',
      maxString: '{{{label}}} не может быть больше {{max}} знаков',
      empty: '{{{label}}} не может быть пустым',
      onlyNumbers: '{{{label}}} может содержать только цифры',
      lengthRequired: '{{{label}}} не верной длины',
      notAllowedKPPForType10: '{{{label}}} не должен быть заполнен для этого типа организации',
      'lengthINN_KFH-short': '{{{label}}} должен быть 10 знаков',
      lengthINN: '{{{label}}} должен быть 10 знаков',
      lengthINN_IP: '{{{label}}} ИП должен содержать 12 знаков',
      lengthINN_KFH_G: '{{{label}}} ГКФХ должен быть 12 знаков',
      lengthOGRN: '{{{label}}} должен быть 13 знаков',
      lengthOGRN_IP: '{{{label}}} ИП должен быть 15 знаков',
      lengthOGRN_KFH_G: '{{{label}}} ГКФХ должен быть 15 знаков',
      lengthOKPO: '{{{label}}} должен быть 8 знаков',
      lengthOKVED: '{{{label}}} должен быть 2 знака',
      lengthOKPO_IP: '{{{label}}} ИП должен быть 10 знаков',
      lengthOKPO_KFH_G: '{{{label}}} ГКФХ должен быть 10 знаков',
      regEx({ name, label }) {
        return getRegExpMessage(name, label);
      },
    },
  },
});
