import styled from 'styled-components';
import { Modal } from '@zendeskgarden/react-modals';

import { Colors } from '../../../../constants/colors';

export const AvatarMockImgHovered = styled.img`
  position: absolute;
  display: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 144px;
  min-width: 144px;
  min-height: 144px;
  overflow: hidden;
  border-radius: 24px;
  border: 1px solid #e9e6f0;
  cursor: pointer;
  &:hover ${AvatarMockImgHovered} {
    display: flex;
  }
`;

export const Avatar = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  width: 108px;
  height: 108px;
`;

export const AvatarMockImg = styled.img`
  height: 144px;
  width: 144px;
  min-width: 144px;
`;

export const AvatarMockImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CameraSvgWrapper = styled.div`
  position: absolute;
  left: -1px;
  bottom: -1px;
  z-index: 10;
  width: 40px;
  height: 40px;
`;

export const StyledCameraSvg = styled.img`
  min-width: 40px;
  min-height: 40px;
`;

export const StyledUserAvatarSvg = styled.img``;

export const AvatarMock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${Colors.gray};

  &:hover > ${AvatarMockImg} {
    display: none;
  }
  &:hover > ${AvatarMockImgHovered} {
    display: flex;
  }

  &:hover ${CameraSvgWrapper} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  @media (min-width: 768px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
`;

export const LinkWrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
`;
export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: ${Colors.darkGray};
  margin: 0;
`;

export const FileUploader = styled.input`
  display: none;
`;

export const StyledModalComponent = styled(Modal)`
  padding: 24px 16px;
  overflow: auto;
  left: auto;

  @media (min-width: 768px) {
    width: 600px;
    padding: 40px 60px;
  }
`;

export const DialogTitle = styled.div`
  font-weight: 600;
  border-bottom: 0.5px solid #e9e6f0;
  width: 100%;
  text-align: left;
  padding-bottom: 16px;

  @media (max-width: 767px) {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 20px;
  }

  @media (min-width: 768px) {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 28px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    flex-wrap: wrap;
    align-items: space-between;
    max-height: 266px;
  }
`;

export const Disclaimer = styled.div`
  order: 1;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  text-align: left;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    align-self: flex-start;
    order: 1;
    font-size: 14px;
    line-height: 20px;
    width: 50%;
    margin-bottom: 54px;
  }
`;

export const AvatarImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;
  max-height: 144px;
  max-width: 144px;
  width: 144px;
  height: 144px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }

  @media (min-width: 768px) {
    align-self: flex-end;
    order: 4;
    max-height: 202px;
    max-width: 202px;
    width: 202px;
    height: 202px;
  }
`;

export const AvatarImg = styled.img`
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
`;

export const UploadAvatarButtonWrapper = styled.div`
  order: 3;
  width: 100%;

  & > button {
    justify-content: center;
    height: 40px;
    border-radius: 12px;
    color: ${Colors.green};
    border: 1px solid ${Colors.green};
    background: none;

    &:hover,
    &:focus {
      background: ${Colors.green};
      color: ${Colors.white};
    }
  }

  margin-right: 8px;
  cursor: pointer;

  @media (min-width: 768px) {
    align-self: flex-start;
    width: 50%;
    order: 2;
  }
`;

export const StyledFileNameLabel = styled.div`
  width: calc(100% - 23px);
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #f29993;
  width: 100%;
  text-align: left;
`;

export const MsgWrapper = styled.div`
  width: 100%;
  height: 16px;
  margin: 10px 0 20px;
  order: 4;
  @media (min-width: 768px) {
    align-self: flex-start;
    order: 3;
    width: 50%;
  }
`;

export const ActionsButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-top: 0.5px solid ${Colors.grayBorder};
  padding-top: 32px;

  @media (max-width: 767px) {
    padding-top: 20px;
  }
`;

export const CancelButtonWrapper = styled.div`
  width: calc(50% - 4px);
  & > button {
    justify-content: center;
    height: 40px;
    border-radius: 12px;
  }

  @media (min-width: 768px) {
    width: 154px;
    & > button {
      height: 56px;
      border-radius: 20px;
    }
  }

  margin-right: 8px;
`;

export const SaveButtonWrapper = styled.div`
  width: calc(50% - 4px);
  & > button {
    justify-content: center;
    height: 40px;
    border-radius: 12px;
  }

  @media (min-width: 768px) {
    width: 154px;
    & > button {
      height: 56px;
      border-radius: 20px;
    }
  }
`;

export const FileNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const DeleteButton = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 9px;
`;
